.react-flow__panel.react-flow__attribution.bottom.right {
  display: none;
}

.react-flow .react-flow__handle {
  visibility: hidden;
}

.react-flow .react-flow__handle-top {
  top: -10px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -10px;
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 2;
}

.wrapper {
  flex-grow: 1;
  height: 100%;
}
