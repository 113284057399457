.bg-alert-success {
  @apply bg-[#F2FDF5] border-emblue-success;
}

.bg-alert-warning {
  @apply bg-[#FEFCEA] border-emblue-passive;
}

.bg-alert-info {
  @apply bg-[#F0F6FE] border-emblue-primary;
}

.bg-alert-error {
  @apply bg-[#FCF2F2] border-emblue-detractor;
}
